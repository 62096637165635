import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import Img from 'gatsby-image';

// utils
import linkResolver from '../../utils/linkResolver';
import AnimateComp from '../../utils/animateWrapper';

const BannerWrapper = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin-bottom: 40px;
  }

  @media (min-width: 426px) {
    margin-bottom: 90px;
  }
`;

const LinkPath = styled.a`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 35px;
  flex-wrap: warp;
  color: ${({ theme }) => theme.color.textBlack};
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    max-width: fit-content;
    margin: auto;
    grid-gap: 30px;
  }

  @media (max-width: ${({ theme }) => theme.device.laptopL}) {
    width: 100%;
    max-width: fit-content;
    margin: auto;
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
`;

const CustomImg = styled(Img)`
  height: 380px;
  width: 646px;
  object-fit: cover;

  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  color: ${({ theme }) => theme.color.textMenu};
  margin-bottom: 15px;
  max-width: 600px;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 0;
    width: 100%;
    padding: 0 15px;
  }

  @media (max-width: 667px) {
    margin: auto;
  }

  @media (max-width: 500px) {
    margin: 0;
    margin-top: 23px;
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  ${({ theme }) => theme.typography.titleContent()};
  margin: 15px 0 15px 0;
`;

const TitleDescription = styled.div`
  @media (min-width: 667px) {
    max-height: 235px;
    overflow: hidden;
  }
  @media (min-width: 426px) {
    max-height: 210px;
    overflow: hidden;
  }
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BannerLatestNews = ({ thumbnail, title, description, meta }) => {
  return (
    <AnimateComp animationType="fadeIn">
      {({ className }) => (
        <BannerWrapper className={className}>
          <LinkPath href={linkResolver(meta)}>
            <CustomImg
              loading="eager"
              css={`
                height: 380px !important;
                object-fit: cover !important;
                width: 100% !important;

                @media (max-width: 500px) {
                  display: block !important;
                  width: 100% !important;
                }

                @media (max-width: 768px) {
                  max-width: unset !important;
                }

                @media (max-width: 425px) {
                  height: 221px !important;
                }
              `}
              fluid={thumbnail.fluid}
            />
            <div
              css={`
                @media (min-width: 769px) {
                  padding-left: 30px;
                }
              `}
            >
              <TextWrapper>LATEST NEWS</TextWrapper>
              <TitleWrapper>{title[0]?.text}</TitleWrapper>
              <TitleDescription>{RichText.asText(description)}</TitleDescription>
            </div>
          </LinkPath>
        </BannerWrapper>
      )}
    </AnimateComp>
  );
};

export default BannerLatestNews;
