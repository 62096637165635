import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Components
import SEO from '../components/seo';
import BlogCard from '../components/blog/card';
import { ButtonTextNoneLink } from '../components/button';
import Footer from '../components/layout/footer';

// data
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';

// utils
import withTranslate from '../utils/withTransWrapper';
import { useHandleLanguage } from '../contexts/language.context';
import BannerLatestNews from '../components/blog/bannerLatestNews';
import { useMedia } from '../hook/index';
import AnimateComp from '../utils/animateWrapper';

const fadeInWrapper = keyframes`
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.uiColorBG};
  padding: 3em 10em;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    padding: 3em;
  }

  @media (max-width: 767px) {
    padding: 2em;
  }

  @media (max-width: 668px) {
    padding: 2em;
  }
`;

const DetailPage = styled.div`
  animation-name: ${fadeInWrapper};
  animation-duration: 1s;
  opacity: 1;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 59px;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding-top: 40px;
  }
`;

const PostNone = styled.div`
  ${({ theme }) => theme.typography.button()}
  color: ${({ theme }) => theme.color.textLink};
  display: flex;
  height: 60vh;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    flex-flow: row wrap;
  }
`;

const SpanWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    margin-top: 40px;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  margin: 0;
  padding-top: 1.5em;
  width: 60%;
  color: ${({ theme }) => theme.color.textBlack};
  white-space: pre-line;
  h1 {
    ${({ theme }) => theme.typography.BigHeader()};
    margin: 0 auto;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
    ${({ theme }) => theme.typography.contentTitle()};
    padding: 122px 1em 0 1em;
    h1 {
      ${({ theme }) => theme.typography.contentTitle()};
      font-size: 24px;
      padding-top: 0.5em;
    }
  }

  @media (min-width: ${({ theme }) => theme.device.laptop}) and (max-width: ${({ theme }) =>
      theme.device.laptopL}) {
    width: 80%;
    padding-top: 1em;
  }

  @media (max-width: ${({ theme }) => theme.device.tablet}) {
    width: 100%;
  }

  @media (max-width: 425px) {
    width: 100%;
    padding: 82px 0 0 0;
  }
`;

const LoadmoreWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin: 45px 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 50px 0;
  }
`;
const RowDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  min-height: 500px;
  opacity: 1;
  padding-top: 40px;
`;

const fadeInCircle = keyframes`
  0% {
    transform: scale(.3);opacity:0.3;
  }
  100% {
    transform: scale(1.5);opacity:1;
  }
`;

const FadeStyle = styled.div`
  animation-name: ${fadeInCircle};
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: absolute;
  width: 1940px;
  height: 1219px;
  left: 700px;
  top: -847px;
  background: radial-gradient(
    38.84% 50% at 50% 50%,
    rgba(234, 2, 0, 0.28) 0%,
    rgba(234, 2, 0, 0) 60%
  );

  @media (max-width: 1024px) {
    width: 1943px;
    height: 1350px;
    left: -310px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    left: -400px;
  }
`;

const fadeLayout = keyframes`
 0%{opacity:0;}
 50%{opacity:0;}
 100%{opacity:1;}
 `;

const Layout = styled.div`
  animation-name: ${fadeLayout};
  animation-duration: 1000ms;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 329px));
  grid-gap: 75px;
  margin-bottom: 48px;

  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 90px;
  }

  @media (max-width: 768px) {
    grid-gap: 80px;
  }

  @media (max-width: 667px) {
    grid-gap: 33px;
  }

  @media (max-width: 568px) {
    grid-gap: 40px;
    grid-template-columns: 1fr;
  }
`;

const Blog = ({ data }) => {
  const { t } = useHandleLanguage();

  const prismicBlogpage = data.prismicBlogPage;
  const prismicBlogPost = data.allPrismicItBlogPost.edges;
  const [page, setPage] = useState(1);
  const checkSize = useMedia(`(max-width: 1024px)`);

  if (!prismicBlogpage) return null;

  const bannerContent = {
    title: prismicBlogpage.data.blog_page_title.raw,
    meta: {
      type: prismicBlogpage.type,
    },
  };

  const chunks = checkSize ? 5 : 7;
  const renderBlog = () => {
    let paginated = [];
    paginated = Array.from(prismicBlogPost).splice(0, page * chunks);

    const dataHighlight = paginated[0];
    const dataBigBanner = paginated.filter((data) => data !== dataHighlight);

    return (
      <AnimateComp animationType="fadeIn">
        {({ className }) => (
          <RowDetail className={className} id="rowDetailWrapper">
            <BannerLatestNews
              title={paginated[0].node.data.blog_title.raw}
              description={paginated[0].node.data.blog_preview_description.raw}
              thumbnail={paginated[0].node.data.blog_hero_image}
              meta={{
                type: paginated[0].node.type,
                uid: paginated[0].node.uid,
              }}
            />
            <Layout className={className}>
              {dataBigBanner.map((group, index) => (
                <BlogCard
                  key={index}
                  title={group.node.data.blog_title.raw}
                  description={group.node.data.blog_preview_description.raw}
                  thumbnail={group.node.data.blog_hero_image}
                  meta={{
                    type: group.node.type,
                    uid: group.node.uid,
                  }}
                />
              ))}
            </Layout>

            {paginated.length < prismicBlogPost.length && (
              <LoadmoreWrap>
                <ButtonTextNoneLink onClick={() => onLoad()}>
                  <span>+</span> LOAD MORE
                </ButtonTextNoneLink>
              </LoadmoreWrap>
            )}
          </RowDetail>
        )}
      </AnimateComp>
    );
  };

  const onLoad = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Wrapper>
        <FadeStyle />
        <DetailPage>
          <SEO title="Blog" />
          <TitleWrapper>{RichText.render(bannerContent.title)}</TitleWrapper>

          {prismicBlogPost.length > 0 ? (
            <>{renderBlog()}</>
          ) : (
            <PostNone>
              <SpanWrapper>
                <span>{t('coming_soon')}</span>
              </SpanWrapper>
            </PostNone>
          )}
        </DetailPage>
      </Wrapper>
      <Footer />
    </>
  );
};

export default withTranslate(Blog);

export const query = graphql`
  query {
    prismicBlogPage {
      type
      id
      data {
        blog_page_title {
          raw
        }
      }
    }
    allPrismicItBlogPost(sort: { fields: data___blog_post_date, order: DESC }) {
      edges {
        node {
          id
          type
          uid
          tags
          data {
            blog_title {
              raw
            }
            blog_preview_description {
              raw
            }
            blog_image {
              fluid {
                src
              }
            }
            blog_hero_image {
              fluid(maxWidth: 2048) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
