const kebabCase = require('lodash/kebabCase');
const defaultLanguage = 'de-de';

const linkResolver = (doc, link = '') => {
  const properties = { lang: 'de-de', type: doc.type, uid: doc.uid };

  if (properties.type === 'homepage') {
    return properties.lang === defaultLanguage ? '/' : `/${properties.lang}`;
  }

  if (properties.type === 'case-studies') {
    return properties.lang === defaultLanguage
      ? `/case-studies`
      : `/case-studies/${properties.lang}`;
  }

  if (properties.type === 'sub-case') {
    return properties.lang === defaultLanguage
      ? `/case-studies/${properties.uid}`
      : `/case-studies/${properties.uid}/${properties.lang}`;
  }

  if (properties.type === 'team') {
    return properties.lang === defaultLanguage ? `/team` : `/team/${properties.lang}`;
  }

  if (properties.type === 'privacy-markdown') {
    return properties.lang === defaultLanguage ? `/privacy` : `/privacy/${properties.lang}`;
  }

  if (properties.type === 'imprint-page') {
    return properties.lang === defaultLanguage ? `/imprint` : `/imprint/${properties.lang}`;
  }

  if (properties.type === 'blog-page' && !link) {
    return `/blog`;
  }

  if (properties.type === 'blog-page' && !!link) {
    return `/blog/tags/${kebabCase(link)}`;
  }

  if (properties.type === 'blog-post') {
    return `/blog/${properties.uid}`;
  }

  return '/';
};

module.exports = linkResolver;
